import request from '@/utils/request'

const BASE_URL = process.env.VUE_APP_BASE_URL

/**
 * Get All Parent Routes
 * @param { Object } data 
 */
const getAllRoutes = (params) => {
  return request({
    url: `${BASE_URL}/routeCategories?search_value=${params.search_value}&page=${params.page}&per_page=${params.per_page}`,
    method: 'GET'
  })
}

/**
 * Get All Child Routes
 * @param { Object } data 
 */
const getAllRoutesList = (params) => {
  return request({
    url: `${BASE_URL}/routeListsByRun?routeRunId=${params.routeRunId}`,
    method: 'GET'
  })
}

/**
 * Save all routes list items
 * @param { Object } data 
 */
const saveRoutesListItems = (data) => {
  return request({
    url: `${BASE_URL}/routes`,
    method: 'POST',
    data
  })
}

export {
  getAllRoutes,
  getAllRoutesList,
  saveRoutesListItems
}