import request from '@/utils/request'

const BASE_URL = process.env.VUE_APP_BASE_URL

/**
 * Get Route Route Log
 */
const getRouteRouteLog = id => {
  return request({
    url: `${BASE_URL}/routes/${id}`,
    method: 'get'
  })
}

/**
 * Create new routeLog for non existing
 * @param { Object } data
 */
const createRouteLog = data => {
  return request({
    url: `${BASE_URL}/${data.route_id}/routeLogs`,
    method: 'POST',
    data
  })
}

/**
 * Toggling RouteLog Ended Status
 */
const toggleRouteLog = data => {
  return request({
    url: `${BASE_URL}/routeLogs/toggle`,
    method: 'POST',
    data
  })
}

/**
 * saveSummary
 */
const saveSummary = data => {
  return request({
    url: `${BASE_URL}/routeLogs/saveSummary`,
    method: 'POST',
    data
  })
}

/**
 * get average fill
 */
const getAverageFill = data => {
  return request({
    url: `${BASE_URL}/routeLogs/averageFill/${data.id}/${data.noOfRoutes}`,
    method: 'GET'
  })
}

const getVehicle = data => {
  return request({
    url: `${BASE_URL}/vehicles`,
    method: 'GET',
    data
  })
}

const checkCloseRoute = id => {
  return request({
    url: `${BASE_URL}/checkCloseRoute/${id}`,
    method: 'get'
  })
}

const checkRoute = data => {
  return request({
    url: `${BASE_URL}/checkRoute/${data.routeId}/${data.userId}`,
    method: 'get'
  })
}


export {
  getRouteRouteLog,
  createRouteLog,
  toggleRouteLog,
  saveSummary,
  getAverageFill,
  getVehicle,
  checkCloseRoute,
  checkRoute
}
